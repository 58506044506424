import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ingredientes:[],
    usuario:null,
    msg:null,
    compras:[],
    buscaBlog:'',
    marcadorBlog:'',
    completarCadastro:false,
    alertaCadastro:false,
    loaded:false
  },
  mutations: {
    alterarLoaded(state,payload){
      state.loaded = payload;
    },
    showAlertaCadastroFinalizado(state,payload){
      state. alertaCadastro = payload;
    },
   showAlertaCadastro(state,payload){
      state.completarCadastro = payload;
    },
    alterar(state,payload){
      state.ingredientes = payload;
    },
    logarUsuario(state,payload){
      state.usuario = payload;
    },

    alterarMensagem(state,payload){
      state.msg = payload;
    },
    alterarCompras(state,payload){
      state.compras.push(payload);
    },
    deletarReceitaCompras(state,payload){
      state.compras.splice(payload,1);
    },
    carregarCompras(state,payload){
      state.compras = payload;
    },
    mudarPalavra(state,payload){
      state.buscaBlog = payload;
    },
    mudarMarcador(state,payload){
      state.marcadorBlog = payload;
    }

  },
  actions: {
  },
  modules: {
  }
})
