import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index.js'
import VueMeta from 'vue-meta'


Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/receitas',
    name: 'Receitas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "receitas" */ '../views/Receitas.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/cadastro',
    name: 'Cadastro',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cadastro" */ '../views/CadastroNovo.vue')
  },
  {
    path: '/parceria',
    name: 'Parceria',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "parceria" */ '../views/Parceria.vue')
  },
  {
    path: '/cp02',
    name: 'ParceriaSuperopa',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "parceria" */ '../views/ParceriaSuperopa.vue')
  },
  {
    path: '/sodexomaissaude',
    name: 'ParceriaSodexo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "parceria" */ '../views/ParceriaSodexo.vue')
  },
  {
    path: '/9453',
    name: 'ParceriaIDEC',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "parceria" */ '../views/ParceriaIDEC.vue')
  },
  {
    path: '/pmqt',
    name: 'ParceriaQuitandinha',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "parceria" */ '../views/ParceriaQuitandinha.vue')
  },
  {
    path: '/promeuorg',
    name: 'ParceriaEuOraganico',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "parceria" */ '../views/ParceriaEuOrganico.vue')
  },
  {
    path: '/perfil',
    name: 'Perfil',
    meta:{requiresAuth:true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "perfil" */ '../views/Perfil.vue')
  },
  {
    path: '/compartilhe',
    name: 'Compartilhe',
    meta:{requiresAuth:true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "compartilhe" */ '../views/Compartilhe.vue')
  },
  {
    path: '/criarReceita',
    name: 'CriarReceita',
    meta:{requiresAuth:true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "criarReceita" */ '../views/CriarReceita.vue')
  },
  {
    path: '/editarReceita/:id',
    name: 'EditarReceita',
    meta:{requiresAuth:true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "editarReceita" */ '../views/CriarReceita.vue')
  },
  {
    path: '/visualizarReceita/:id',
    name: 'VisualizarReceita',
    meta:{requiresAuth:true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "visualizarReceita" */ '../views/VisualizarReceita.vue')
  },
  {
    path: '/visualizarReceitaCampanha/:id',
    name: 'VisualizarReceitaCampanha',
    
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "visualizarReceitaCampanha" */ '../views/VisualizarReceitaCampanha.vue')
  },
  {
    path: '/cadastroCampanha',
    name: 'CadastroCampanha',
    
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cadastroCampanha" */ '../views/CadastroCampanha.vue')
  },
  {
    path: '/receitas-para-testar',
    name: 'ReceitasTestar',
    meta:{requiresAuth:true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "receitasTestar" */ '../views/ReceitasTestar.vue')
  },
  {
    path: '/livro-de-receitas',
    name: 'LivroReceitas',
    meta:{requiresAuth:true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "livroReceitas" */ '../views/LivroReceitas.vue')
  },
  {
    path: '/lista-de-compras',
    name: 'ListaCompras',
    meta:{requiresAuth:true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "listaCompras" */ '../views/ListaCompras.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue')
  },
  {
    path: '/postagem/:id',
    name: 'Postagem',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "postagem" */ '../views/Publicacao.vue')
  },
  {
    path: '/politica-de-privacidade',
    name: 'Politica',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "politica" */ '../views/Politica.vue')
  },
  {
    path: '/termos-de-uso',
    name: 'Termo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "termo" */ '../views/Termo.vue')
  },
  {
    path: '/planos',
    name: 'Pagamento',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pagamento" */ '../views/Pagamento.vue')
  },
  {
    path: '/transacoes',
    name: 'Transacoes',
    meta:{requiresAuth:true},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "transacoes" */ '../views/Transacoes.vue')
  },
  {
    path: '/resgate',
    name: 'Resgate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "resgate" */ '../views/Resgate.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to,from,next) => {

  let user =  read_cookie('usuario');

  if(to.matched.some(record => record.meta.requiresAuth)){
    
    if(user == null){
      store.commit('logarUsuario',null);
      if(to.name == "VisualizarReceita")
      {
        router.push("/login");
      }else{
        router.push("/");
      }
    
    }else{
      store.commit('logarUsuario',user);
      if(user.ativo == false){
        if(to.name != 'Perfil' && to.name != 'Transacoes'){
          router.push("/planos");
        }else{
          next();
        }
       
      }else{
        next();
      }
     
    }

  }else{
    if(user != null){
      store.commit('logarUsuario',user);
      if(to.name == "Home"){router.push("/receitas");}
     }
    next();
  }

});

function read_cookie(name) {
  var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
  result && (result = JSON.parse(result[1]));
  return result;
 }

export default router
