<template>
  <div class="home-container">
    
    <!-- -->
      <div class="container-fluid bg-tarimba">
        <div class="row">
          <div class="col-sm-12">
              <img  src="/img/logo_fonte_branca.png" class="img-fluid mx-auto d-none d-sm-block logo-tarimba-home" alt="Logo Tarimba" />
              <img  src="/img/logo_fonte_branca.png" class="img-fluid mx-auto d-block d-sm-none logo-tarimba-home " style="margin-top:230px;" alt="Logo Tarimba" />
              <h1  class="text-center txt-combina">Combina seus ingredientes com criatividade</h1>
            <br>  <br>  
              
          </div>
        </div>

        <div class="container">
           <div class="row"> 
             <div class="col-sm-12">
                <h1 class="h2-mobile text-center " style="color:#F18800;font-weight:bold;font-family: plumeBold;font-size:3em;">Encontre receitas com os ingredientes que você tem na sua geladeira e na sua despensa.
               </h1>
               

<br><br>

 <div class="row"> 
    <div class="col-sm-7"> 
  <ul class="text-white lista-home h2-mobile">
    <li>Receitas de reaproveitamento de sobras</li>
    <li>Filtros para dietas, categorias e custos</li>
      <li>Receitas com cascas e talos</li>
      <li>Todas em no máximo 6 passos</li>
  </ul>
</div>  

 <div class="col-sm-5">
     <router-link to="/receitas"><img  src="/img/cliqueAqui.jpeg" class="img-fluid float-right d-none d-sm-block" alt="Clique aqui Tarimba" /></router-link>
     <router-link to="/receitas"><img  src="/img/cliqueAqui.jpeg" class="img-fluid d-block mx-auto d-sm-none" alt="Clique aqui Tarimba" /></router-link>
  </div>

</div>  





<br><br>
              </div>
           </div> 
        </div>

      </div>

       <div class="container-fluid bg-laranja">
          <div class="row">
            <div class="col-sm-12 padding-lateral"><br><br>
               <h2 style="" class="text-center txt-experimente bold">Experimente o jeito tarimbado de cozinhar por 60 dias.<br>
               Faça seu cadastro na nossa degustação <span style="color:#440D45;cursor:pointer;" @click="abrirDegustacao" ><strong>Clicando aqui.</strong></span></h2>
               
              <br><br>
            </div>
          </div>
       </div>


        <div class="container-fluid bg-roxo">
          <div class="row">
            <div class="col-sm-12 padding-lateral"><br><br>
               <h1 style="" class="txt-experimente">Uma equipe Tarimbada</h1>
                <br><br>
                 <h3 class="txt-falar" style="color:#FFF;font-weight:500;" > Pedimos licença para nos apresentarmos.<br><br></h3>
             
               <h3 class="txt-falar" style="color:#F18800;font-weight:bold;" > <span @click="abrirModal" style="cursor:pointer;">Clique aqui</span> <span style="color:#FFF;font-weight:normal;" >e conheça um pouco da nossa história.</span></h3>
               <br><br>
            </div>
          </div>
       </div>
<br><br>
           <div class="container-fluid" id="parcerias">

<div class="row">
   <div class="col-sm-12" style="padding-left:6%;padding-right:6%;">
      <h1 style="" class="bold">Sua empresa tem tarimba?</h1>
              <p class="txt-texto">Se o seu negócio é do universo da culinária, venha fazer parte da Tarimba na Cozinha.<br>
Passe-nos seus dados que entraremos em contato.</p>
<div v-if="showMessage" class="alert alert-warning" role="alert">
                  Dados enviados com sucesso.
                </div>
   </div>
</div>



          <div class="row">
            <div class="col-sm-6 divisor" style="padding-left:6%;">
               

                
                  <div class="form-group">
                    <input v-model="nome" type="text" class="form-control form-envio" name="nome" placeholder="Nome" aria-describedby="nome" required />
                    <small class="form-text text-muted"></small>
                  </div>
                  <div class="form-group">
                    <input v-model="empresa" type="text" class="form-control form-envio" name="empresa" placeholder="Empresa" aria-describedby="empresa" required />
                    <small  class="form-text text-muted"></small>
                  </div>
                  <div class="form-group">
                    <input v-model="telefone" v-mask="'(##) ####-####'"  type="tel" class="form-control form-envio" name="telefone" placeholder="Telefone" aria-describedby="Telefone" required />
                    <small  class="form-text text-muted"></small>
                  </div>
                   <div class="form-group">
                    <input v-model="celular" v-mask="'(##) #####-####'" type="tel" class="form-control form-envio" name="celular" placeholder="Celular" aria-describedby="Celular" />
                    <small  class="form-text text-muted"></small>
                  </div>
                   <div class="form-group">
                    <input v-model="email" type="email" class="form-control form-envio" name="email" placeholder="E-mail" aria-describedby="email" required />
                    <small  class="form-text text-muted"></small>
                  </div>
              

            </div>

              <div class="col-sm-6" style="padding-right:6%;">
                <div class="form-group">
                    <textarea rows="9" style="height:264px !important;" maxlength="500" v-model="mensagem" class="form-control form-envio" name="mensagem" placeholder="Deixe sua mensagem..."  ></textarea>
                    <small  class="form-text text-muted"></small>
                  </div>

              
                <button type="button" role="button" @click="enviarContato" class="btn bt-roxo btn-primary bold ">ENVIAR</button>
                <h4 class="float-right bold">Fale conosco!</h4>
            </div>

          </div>
       </div>
       <br><br>
   <!-- -->

 <Degustacao />

   <div class="modal" id="modal-home" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Apresentação dos sócios</h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Cozinhar é uma atividade que exige, além de técnica e conhecimento, muita dedicação e criatividade, um conjunto de elementos que chamamos de Tarimba.<br><br>
Fala sério, dá até um desânimo saber que temos de cozinhar mais uma vez e a inspiração pra decidir o que fazer não vem, não é mesmo?<br><br>
A gente abre o armário de mantimentos, a geladeira, olha aqueles ingredientes, pensa, pensa, e no final acaba sempre repetindo as mesmas preparações.<br><br>
Se por um lado isso aparentemente dá menos trabalho, por outro acaba transformando o cozinhar em algo chato, concorda?<br><br>
Fato é que, com os ingredientes de sempre, a gente pode fazer uma enorme quantidade de receitas diferentes. Isso sem falar no reaproveitamento de sobras, outra fonte de oportunidades.<br><br>
Mas quem é capaz de guardar e acessar tantas receitas assim “de cabeça”?<br><br>
E não adianta recorrer aos livros e sites de receitas, porque eles não estão organizados dessa forma, ou seja, eles não foram feitos pensando na combinação dos ingredientes que a gente tem disponíveis.<br><br>
Com a Tarimba na Cozinha isso agora é possível, pois foi por isso que a criamos: para resolver esse problema de quem cozinha habitualmente.<br><br>
Quando criamos a Tarimba na Cozinha quisemos tornar sua vida na cozinha mais prática e prazerosa. <br><br>
Quem somos nós?<br><br>
Somos 2 sócios com perfis e histórias completamente diferentes, mas unidos pela mesma paixão: cozinhar.<br><br><br>

<strong>Marcelo Fernandes</strong> ( <a target="_blank" href="https://www.linkedin.com/in/marcelo-fernandes-a05050128/">https://www.linkedin.com/in/marcelo-fernandes-a05050128/ </a>)<br><br>
Estudou gastronomia no SENAC Rio e de lá foi direto para a rede Sheraton, onde atuou primeiramente na unidade do Rio de Janeiro e depois no Sheraton Frankfurt, na Alemanha.<br><br>
Chefiou a cozinha de grandes hotéis como o Sheraton Petribu, em Recife, o Costão do Santinho, em Florianópolis, e o Hotel Transamérica, na Ilha de Comandatuba. <br><br>
Fora da hotelaria, empreendeu em restaurantes próprios e realizou festivais gastronômicos nos EUA, Argentina, México e Paraguai.<br><br>
Atuou também durante anos como Chef da renomada Escola Laurent e foi Chef de desenvolvimento para América Latina da Unilever Brasil.<br><br>
São mais de 35 anos de carreira, muito conhecimento e histórias compartilhadas por Marcelo aqui na Tarimba na Cozinha.<br><br><br>

<strong>André Victória da Silva </strong> (<a target="_blank" href="https://www.linkedin.com/in/andrevictoriadasilva/"> https://www.linkedin.com/in/andrevictoriadasilva/ </a>)<br><br>
Profissional empreendedor e curioso, é o idealizador da Tarimba na Cozinha. Filho de dono de restaurantes, sempre esteve de alguma forma envolvido com a culinária. Formou-se em hotelaria em 1987, começou sua carreira na cozinha, mas logo passou a ocupar funções de gestão na área de A&B (Alimentos e Bebidas) do então Quatro Rodas de Olinda. Mesmo como Gerente Geral de grandes hotéis pelos quais passou, nunca perdeu o gosto por sua área favorita. Também atuou por muitos anos na área de treinamento e qualificação profissional, conhecimento que aplica em diferentes contextos e situações, como é o caso da Tarimba na Cozinha.



        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        
      </div>
    </div>
  </div>
</div>

 <div class="modal" id="modal-hotsite" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header py-0 " style="background-color:#803B73;border-bottom:none !important;">
        <h5 class="modal-title"></h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close" style="color:#FFF;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body py-0 px-0 modal-hotsite" style="background-color:#803B73;">
       <iframe src="https://tarimbanacozinha.com.br/TarimbadosContraFome/img/brasilsemfome.html"  frameborder="0" style="width:100%;height:450px;" />
      </div>
      
    </div>
  </div>
</div>

  </div>
</template>

<style>
@media all and (max-width:500px){
.modal-hotsite{height:250px !important;}
}
@media all and (max-width:420px){
.modal-hotsite{height:230px !important;}
}
@media all and (max-width:390px){
.modal-hotsite{height:190px !important;}
}
.lista-home{font-family:plumeBold;font-size:1.8em;list-style-image: url("/img/tick.png")}
.bg-tarimba{background:url('/img/background.png');background-size: cover;background-repeat: no-repeat;}
.logo-tarimba-home{margin-top:20%;}
.txt-combina{color:#FFF;font-size:2.3em;}
.input-busca{height:64px !important;color:#000 !important;font-size:1.5em !important;}
.btn-buscar{padding:17px 0 !important;background:#F18800 !important;font-weight:bold !important;color:#FFF !important;width:100%;font-size:1.8em !important;border:none !important;}
.txt-experimente{color:#FFF;font-weight:bold;font-size:2.5em;}
.bg-laranja{background:url('/img/landing_page.87d6c14d0f000971b465.svg');}
.txt-comecar{color:#440D45;font-weight:bold;font-size:3.2em;}
.bg-roxo{background-color:#440D45;background-image:url('/img/imagem_landing.png');background-repeat: no-repeat;background-position: right;}
.padding-lateral{padding:20px 6% !important;}
.divisor{border-right:2px solid #000;}
.txt-texto{font-size:1.4em;}
.bt-roxo{background-color:#440D45 !important;border:none !important;}
.form-envio{color:#1D1D1D !important;border:2px solid #440D45 !important;padding:25px 10px !important;}
.search-input{height:64px !important;color:#000 !important;font-size:1.5em !important;display: block !important;
    width: 100% !important;}
.search-result-list{background:#FFF !important;margin:0 !important;padding:0 !important;}
.search-result-list li{list-style:none;cursor:pointer;margin:0 !important;padding:5px 5px !important;font-size:1.2em;border-bottom:1px solid #e3e3e3;}
.search-result-list li:hover{background:#5cb8f2 !important;color:#FFF !important;}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #1D1D1D !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #1D1D1D !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #1D1D1D !important;
}

.btn-contact[_ngcontent-eoh-c8] {
    width: 100%;
    border-radius: 10px;
    text-align: left;
    background-color: #fff;
}
.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}
.btn:hover {
    color: #212529;
    text-decoration: none;
}

@media all and (max-width:1460px){
.txt-falar{font-size:1.5em !important;}
}
@media all and (max-width:1366px){
.txt-falar{font-size:1.3em !important;}
}


@media all and (max-width:768px){
  .bg-tarimba{background:url('/img/bg-mobile.jpg') !important;background-size: cover !important;background-repeat: no-repeat !important;}
  .txt-combina{font-size:1.2em !important;}
  .txt-experimente,.txt-comecar,h1{font-size:2em !important;}
  .h2-mobile{font-size:1.4em !important;}
  .bg-roxo{background-image:none;}
}
</style>

<script>
//import axios from 'axios'
import servidor from '@/servidor'
//import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Degustacao from '@/components/Degustacao'


export default {
  name: 'Home',
  metaInfo: {
    title: 'Home - Tarimba na cozinha',
    meta:[{name: 'description', 
    content: "Encontre receitas com os ingredientes que você tem na sua geladeira e na sua despensa. Receitas de reaproveitamento de sobras, Filtros para dietas, categorias e custosReceitas com cascas e talos. Todas em no máximo 6 passos."},
    { property: 'og:image', content: 'https://tarimbanacozinha.com.br/img/logo_tarimba.jpg' },
      { property: 'og:url', content: 'https://tarimbanacozinha.com.br/login' },
         { property: 'og:type', content: 'website' },
            { property: 'og:image:type', content: 'image/jpeg' },
       { property: 'og:title', content: 'Tarimba na cozinha - Receitas' },
       { property: 'og:description', content: 'Encontre receitas com os ingredientes que você tem na sua geladeira e na sua despensa.' }
    
    ]
  },
  components: {Degustacao},
  data(){
    return {
      solicitacao:"",
      limiteResultados:20,
      resultados: [],
      selectedItem: null,
      query: '',
      ingredientes:[],
      nome:'',
      empresa:'',
      celular:'',
      telefone:'',
      email:'',
      mensagem:'',
      showMessage:false,
      showMessageAutocomplete:false
    }
  },
methods:{
  enviarSolicitacao(){
      this.enviarPalavra(this.solicitacao);
      this.solicitacao = '';
      alert('Registramos sua solicitação e a partir de agora priorizaremos a busca e inserção de receitas com o mesmo.');
    },
enviarPalavra(palavra) {
        let formData = new FormData();
        formData.append('nome',palavra);
        fetch(servidor+'endpoint/palavras',{method: "POST",body: formData})
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          });
  },

  abrirModal(){
    window.jQuery('#modal-home').modal('show');
   },
   abrirDegustacao(){
    window.jQuery('#modal-degustacao').modal('show');
   },
    fecharBoxIngrediente(){
      this.showMessageAutocomplete = false;
    },
    checar(){
      if(this.query.length > 0){
        if(this.resultados.indexOf(this.query) == -1){
        this.showMessageAutocomplete = true;
        //this.enviarPalavra(this.query); 
        }else{
          this.onSubmit(this.query);
        }
      }
    },
     search() {
       if(this.query.length > 1){
        let formData = new FormData();
        formData.append('query',this.query);
        fetch(servidor+'autocomplete/ingredientesComReceita',{method: "POST",body: formData})
          .then((response) => response.json())
          .then((data) => {
            this.resultados = data;
          })
       }
    },

    onSubmit(result) {
   
     this.ingredientes.push(result);
     this.query = "";
     this.$refs.typeahead.inputValue = "";
     this.showMessageAutocomplete = false;
     this.$store.commit('alterar',this.ingredientes);
     
    },
    removerIngrediente(index){
      this.ingredientes.splice(index,1);
      this.$store.commit('alterar',this.ingredientes);
    },
    enviarContato(){
      if(this.telefone.length == 0 && this.celular.length == 0 && this.email.length == 0){ 
        alert("Informe pelo menos um tipo de contato: E-mail, Telefone ou celular.");
        return;
        }
      if(this.nome.length == 0 ||  this.empresa.length == 0){
        alert("Nome e empresa são obrigatórios!");
       return;

       }else{
       
        let formData = new FormData();
        formData.append('nome',this.nome);
        formData.append('email',this.email);
        formData.append('empresa',this.empresa);
        formData.append('celular',this.celular);
        formData.append('telefone',this.telefone);
        formData.append('mensagem',this.mensagem);
         fetch(servidor+'endpoint/contato',{method: "POST",body: formData})
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            this.showMessage = true;
            this.nome = "";
            this.empresa = "";
            this.email = "";
            this.telefone = "";
            this.celular = "";
             this.mensagem = "";
          })
       }
    }
  },
  mounted(){
    this.ingredientes = this.$store.state.ingredientes;
    /*if(this.$store.state.loaded == false){
      window.jQuery('#modal-hotsite').modal('show');
      this.$store.commit('alterarLoaded',true);
    }*/
   
    /*let contexto = this;
    window.jQuery('.campo-busca').keyup(function(e) {
      if(e.key === "Enter"){ contexto.checar();}
    });*/
  
  }
}
</script>
