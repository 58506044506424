<template>
  <div class="container-degustacao">


 <div class="modal" id="modal-degustacao" tabindex="-1" >
  <div class="modal-dialog modal-dialog-centered" >
    <div class="modal-content" style="background-color: #400041;">
      <div class="modal-header">
        <h4 class="modal-title text-white">Conta de Degustação</h4>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <ul class="text-white">
          <li>
            A degustação gratuita é válida por 60 dias  e só é possível apenas uma vez por usuário.
          </li>
           <li>
            Ao término da degustação, se quiser continuar tendo acesso total ao site, você precisará adquirir um plano de assinatura.
          </li>


            <li>
           Você não assume obrigação alguma de se tornar assinante ao fazer esse cadastro na degustação.

          </li>
        </ul>

        <p class="text-white">Por favor, faça seu cadastro simplificado abaixo:</p>

        <!-- <div class="form-group">
                    <input class="form-control input-cadastro" name="cpf" placeholder="CPF" type="text" v-mask="'###########'" v-model="cpf"/>
                </div>  -->
     
                <div class="form-group">
                    <input class="form-control input-cadastro" name="nome" placeholder="Nome" type="text" v-model="nome"/>
                </div>   

<br>

                 <div class="form-group">
                    <input class="form-control input-cadastro" name="email" placeholder="E-mail" type="email" v-model="email"/>
                </div>  

                <div class="form-group">
                    <input class="form-control input-cadastro" name="emailConfirma" placeholder="Confirmar E-mail" type="email" v-model="emailConfirma"/>
                </div>  
<br>

                 <div class="form-group">
                    <input class="form-control input-cadastro" name="senha" placeholder="Senha" type="password" v-model="senha"/>
                </div> 

                <div class="form-group">
                    <input class="form-control input-cadastro" name="senhaConfirma" placeholder="Confirmar Senha" type="password" v-model="senhaConfirma"/>
                </div> 

 <li class="text-white"><input type="checkbox" name="termos" v-model="termos" />&nbsp;&nbsp;Li e aceito os <span class="bold" ><router-link style="font-size:0.8em !important;color:#FFF;"  to="/termos-de-uso" target="_blank">TERMOS DE USO</router-link></span></li>
                     <li class="text-white"><input type="checkbox" name="politica" v-model="politica" />&nbsp;&nbsp;Li e aceito as <span class="bold"><router-link style="font-size:0.8em !important;color:#FFF;" to="/politica-de-privacidade" target="_blank">POLÍTICAS DE PRIVACIDADE</router-link></span></li>
                     <br>
                
<div v-if="showError" class="alert alert-danger">
  {{msg}}
</div>

      </div>
      <div class="modal-footer">
         <button  v-if="showLoading == false" type="button" class="btn btn-secondary d-block w-100 text-dark" style="background-color:white;" @click="validarCampos" >FINALIZAR CADASTRO NA DEGUSTAÇÃO</button>
          <button type="button" class="btn btn-secondary d-block w-100 text-dark" @click="goToPlanos" style="background-color:white;"  >CONHEÇA NOSSOS PLANOS DE  ASSINATURA</button>
          <div v-if="showLoading" class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
      
        
        
      </div>
    </div>
  </div>
</div>


 
  </div>
</template>

<script>
import servidor from '@/servidor'


export default {
  name: 'Degustacao',
  components: {},
 
  data(){
    return {
     nome:'',
      cpf:'',
      email:'',
      emailConfirma:'',
      senha:'',
      senhaConfirma:'',
      termos:null,
      politica:null,
      showError:false,
      msg:"",
      showLoading:false,
     
    }
  },
  methods:{
    goToPlanos(){
      window.jQuery('#modal-degustacao').modal('hide');
      this.$router.push('/planos');
    },
   fecharModal(){
    window.jQuery('#modal-degustacao').modal('hide');
   },
    abrirAlerta(msg){
      this.msg = msg;
      this.showError = true;
    },
   validarCampos(){
    
     if(this.nome.length < 4){this.abrirAlerta("Nome deve conter pelo menos 4 caracteres!");return;}
      //if(this.cpf.length < 11){this.abrirAlerta("CPF é obrigatório e deve conter 11 caracteres!");return;}
      //if(this.isValidCPF(this.cpf) == false){this.abrirAlerta("CPF Inválido!");return;}
     
      if(this.email.length < 5){this.abrirAlerta("E-mail inválido!");return;}
      if(this.email != this.emailConfirma){this.abrirAlerta("Confirmação de E-mail não combina!");return;}
      if(this.senha.length < 8){this.abrirAlerta("Senha deve conter pelo menos 8 caracteres!");return;}
       if(this.senha != this.senhaConfirma){this.abrirAlerta("Confirmação de Senha não combina!");return;}
      
      if(this.termos == null){this.abrirAlerta("Você não confirmou a respeito dos (Termos de uso).!");return;}
      if(this.politica == null){this.abrirAlerta("Você não confirmou a respeito da (Política de privacidade).!");return;}
      this.enviar();
   },

   isValidCPF(cpf) {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999" 
    ) {
        return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++) 
        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(9, 10)) ) return false
    soma = 0
    for (var j = 1; j <= 10; j++) 
        soma = soma + parseInt(cpf.substring(j-1, j)) * (12 - j)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(10, 11) ) ) return false
    return true
    },

     enviar(){
      this.showLoading = true;
      let dados = new FormData();
      dados.append('nome',this.nome);
      dados.append('email',this.email);
      dados.append('senha',this.senha);
      dados.append('cpf',this.cpf);
     

      fetch(servidor+'verificacao/usuario',{method:"POST", body:dados})
          .then((response) => response.json())
          .then((data) => {
            this.showLoading = false;
            console.log(data);

            if(data.erro != null){
                this.abrirAlerta(data.erro);
            }else{
               console.log('Registrado com sucesso');
               this.$store.commit('logarUsuario',data);
               this.setCookie('usuario',JSON.stringify(this.logado), this.logado.dias);
               this.$store.commit('showAlertaCadastroFinalizado',true);
               this.fecharModal();
               this.$router.push('/perfil');
            }
          }).catch(this.showLoading = false);
    },
    setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
   
  },
  computed:{
   logado(){
      return this.$store.state.usuario;
    }
  },
  mounted(){
    
    
  },
 
}
</script>

