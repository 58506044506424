import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import jQuery from 'jquery'
window.jQuery = window.$ = jQuery
global.jQuery = jQuery
require('bootstrap')
import 'bootstrap/dist/css/bootstrap.css'
var VueScrollTo = require('vue-scrollto');
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
Vue.use(VueScrollTo)
import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)
import 'vue-anka-cropper/dist/VueAnkaCropper.css';

//import VueAnalytics from 'vue-analytics'
//and then use it in main.js
//Vue.use(VueAnalytics, {
//  id: 'G-9TYEDFCMRF',
//  router
//})
import VueGtag from "vue-gtag"
Vue.use(VueGtag, {
  config: { id: "G-9TYEDFCMRF" }
}, router);



(function(){
var s = document.getElementsByTagName("script")[0];
var b = document.createElement("script");
b.type = "text/javascript";
b.async = true;
b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
s.parentNode.insertBefore(b, s);
})();
window._linkedin_partner_id = "4028193"; //paste your id here
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push(window._linkedin_partner_id);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
